import React from 'react';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import background from '../ui/back.png';
import face from '../ui/CEOimg.png';
import { Link, Element, scroller } from 'react-scroll';
//import app.css
import '../index.css';

const WellSite = () => {
    return (
        <Container>
    <Box sx={{ minHeight: '100vh', color: 'white' }}>
    <Box 
      sx={{
            position: 'absolute',
              left: -2,
              top: -2,
            width: '100%',
            height: '100%',
            zIndex: 0,
            backgroundSize: 'cover',
            backgroundImage: `url(${background})`,

      }}>
      <Container 
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: { xs: 'none', md: 'center'},
          textAlign: 'center',
              pt: 8 }}>
        <Grid container spacing={2} sx={{ justifyContent:'center', alignItems: 'center', 
        }}>

        <Grid item xs={12} md={6}
        sx={{ }}> <Typography 
          variant="h3" 
          color="white" 
          fontWeight="bold" 
          gutterBottom 
          className="custom-font"
          sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}
        >
          Velkommen til Softrunner
        </Typography>
        <Typography 
          variant="h6" 
          color="white" 
          fontWeight="bold" 
          gutterBottom 
          sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}
        >
          Skræddersyede løsninger inden for
        </Typography>
        <Typography 
          variant="h6" 
          color="white" 
          fontWeight="bold" 
          gutterBottom 
          sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
                  marginBottom: {
                    xs: '4rem',
                    md: '0rem'
                  }
          }}
        >
          software og hardware
        </Typography>
            </Grid>
                <Grid item xs={12} md={6}>
                      <Box
                      component="img"
                      borderRadius="20%"
                      src={face}
                      alt="icon"
                      sx={{
                        width: "15rem"}}>
            </Box>
        <Typography variant="h6" color="white"
        sx={{ fontWeight: 'bold', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>
        Jeg vil vil skabe en løsning
        </Typography>
        <Typography variant="h6" 
        sx={{ fontWeight: 'bold', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>
        der passer til dine behov
        </Typography>
        </Grid>
        </Grid>
        <Container sx={{ justifyContent: 'center', alignItems: 'center', p:3 }}>
        <Button
        
        variant="contained"
        size="large"
        sx={{ borderRadius: '10px',
                marginTop: '2rem',
        }}
        to="kontakt">
        <Link to="kontakt" smooth={true} duration={1000}>Giv Softrunner et kald</Link>
        </Button>
        </Container>
        </Container>
    </Box>
</Box>
</Container>
  );
};

export default WellSite;




