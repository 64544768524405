import React from 'react';

import { Box, Grid, Typography  } from '@mui/material';
import icon from '../ui/smlIcon.png';
import { Link } from 'react-scroll';
import callBack from '../ui/pickOs.png';

const pickoOs = () => {
    return (
    <Box
      minHeight="600px"
      sx={{
        background: 'url(' + callBack + ') no-repeat right',
        
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }} padding={4}>
    <Typography 
      variant="h3" 
      color="white" 
      textAlign="center" 
      gutterBottom 
      sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}
    >Hvorfor vælge Softrunner?
          </Typography>
        <Grid container sx={{ maxWidth:'800px', marginY: 8 }} height="100%" alignItems="center" justifyContent="center">

        <Grid item xs={12} sm={6} >
          <Box
            bgcolor="rgba(0, 0, 0, 0.8)"
            p={3}
            margin={2}
            minHeight="130px"
            textAlign="center"
            borderRadius={2}
            color="white"
          >
            <Typography variant="h5" gutterBottom>
              Sikkerhed i Fokus
            </Typography>
            <Typography variant="body2">
              Vi tager datasikkerhed meget alvorligt. Dine informationer er sårbare, og derfor prioriterer vi beskyttelse og fortrolighed højt, så du kan være tryg.
            </Typography>
          </Box>
          <Box
            bgcolor="rgba(0, 0, 0, 0.8)"
            p={3}
            margin={2}
            textAlign="center"
            minHeight="130px"
            borderRadius={2}
            color="white">

            <Typography variant="h5" gutterBottom>
              Personlig Service 'hos dig'
            </Typography>
            <Typography variant="body2">
              Vi tilbyder kun personlig service direkte hos kunden. Vi løser IT-problemer på stedet for at få hænderne på problemet og sikre en hurtig løsning.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            bgcolor="rgba(0, 0, 0, 0.8)"
            p={3}
            margin={2}
             minHeight="130px"           
            textAlign="center"
            borderRadius={2}
            color="white"
          >
            <Typography variant="h5" gutterBottom>
              Overkommelig Pris
            </Typography>
            <Typography variant="body2">
              IT-support kan være dyrt, men hos Softrunner får du kvalitetsservice til en overkommelig pris. Vi sørger for, at du får værdi for pengene.
            </Typography>
          </Box>
          <Box
            bgcolor="rgba(0, 0, 0, 0.8)"
            p={3}
            margin={2}
            minHeight="130px"
            textAlign="center"
            borderRadius={2}
            color="white"
          >
            <Typography variant="h5" gutterBottom>
              Hurtig Respons
            </Typography>
            <Typography variant="body2">
              Vi reagerer hurtigt og løser dine IT-problemer effektivt. Vores 'Runner' cykler ud til dig og sikrer, at problemerne bliver løst effektivt og hurtigt.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
export default pickoOs;
