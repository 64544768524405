import React from 'react';
import { Box, Container,  Card, CardContent,  Typography, IconButton } from '@mui/material';
import WebIcon from '@mui/icons-material/Web';
import { Element } from 'react-scroll';
import ComputerIcon from '@mui/icons-material/Computer';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import WifiIcon from '@mui/icons-material/Wifi';
import SettingsIcon from '@mui/icons-material/Settings';
import HelpIcon from '@mui/icons-material/Help';



const servicesList = 
[
  {
    title: "Problemer med computer",
    icon: ComputerIcon,
    description: "Vi tilbyder support til alle typer computerproblemer, herunder hardwarefejl, softwareinstallation, virusfjernelse og generel support."
  },
  {
    title: "Problemer med telefon",
    icon: PhoneIphoneIcon,
    description: "Vores eksperter kan hjælpe med problemer på din smartphone, herunder opsætning, app-fejl, opdateringer og hardwareproblemer."
  },
  {
    title: "Problem med netværk",
    icon: WifiIcon,
    description: "Vi løser netværksproblemer som langsomme forbindelser, opsætning af routere, trådløse netværksfejl og sikkerhedskonfigurationer."
  },
  {
    title: "Hjælp til software",
    icon: SettingsIcon,
    description: "Få hjælp til installation, konfiguration og fejlfinding af forskellige softwareprogrammer, fra operativsystemer til specialiserede applikationer."
  },
  {
    title: "Hjælp til dine IT problemer",
    icon: HelpIcon,
    description: "Vi tilbyder generel IT-support til både privatpersoner og virksomheder, herunder rådgivning, fejlfinding og optimering af dine IT-systemer."
  }
]
const Services = () => {
    return (
        <Element name="services">
        <Box>
        <Typography variant="h4" align="center" sx={{ mt: 4, }}>
        Vores Services
        </Typography>
        <Box sx={{ minHeight: '60vh', backgroundColor: 'white',
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                //add rows to the container
                display: 'flex',
                position: 'relative'  }}>
    <Box
      sx={{
        position: 'relative',
        height: '35vh',
        display: 'flex',
        overflowX: 'auto',
        padding: '16px',
        '&::-webkit-scrollbar': {
          height: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888',
          borderRadius: '10px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          backgroundColor: '#555',
        },
      }}
    >
      {servicesList.map((service) => (
        <Card
          key={service.id}
          sx={{
            minWidth: '280px',
            marginRight: '16px',
            borderRadius: '16px',
            boxShadow: 3,
            transition: 'transform 0.3s, box-shadow 0.3s',
            '&:hover': {
              transform: 'scale(1.05)',
              boxShadow: 6,
            },
          }}
        >
          <CardContent>
            <Typography variant="h6">{service.title}</Typography>
            <IconButton>
              {<service.icon sx={{ fontSize: '3rem',
                      color: 'primary.main',

              }} />}

            </IconButton>
            <Typography variant="body2">{service.description}</Typography>
          </CardContent>
        </Card>
      ))}
    </Box>    
    </Box>
    </Box>
    </Element>
    );
};

export default Services;

