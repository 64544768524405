import React, { useState } from 'react';
import axios from 'axios';
import { Box, Button, Container, TextField, Typography } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

import callBack from '../ui/callback.png';
import SendIcon from '@mui/icons-material/Send';
import { Element } from 'react-scroll';

const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    //msg is sedt to server boolean
    const [msgSent, setMsgSent] = useState(false);

    const fetchToken = async () => {
        try {
            const response = await axios.get('http://192.168.1.178:3000/api/get/token');
            return response.data;
        } catch (error) {
            console.error('Error fetching token:', error);
            throw new Error('Failed to fetch token');
        }
    };

    const sendEmail = async (name, email, message, token) => {
        try {
            const response = await axios.post('http://192.168.1.178:3000/api/send/email', {
                name,
                email,
                message,
                token,
            });
            return response.data;
        } catch (error) {
            if (error.response && error.response.data.message === 'Invalid token') {
                console.error('Invalid token:', error.response.data.message);
                // Handle invalid token error
            } else {
                console.error('Error sending email:', error);
                // Handle other errors
            }
            throw error;
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const token = await fetchToken();
            const responseData = await sendEmail(name, email, message, token);
            setName('');
            setEmail('');
            setMessage('');
            setMsgSent(true);

            // You can also add some success message display logic here if needed
        } catch (error) {
            console.error('Submission error:', error);
        }
    };

    return (
        <Element name="kontakt">
            <Box
                sx={{
                    minHeight: '100vh',
                    background: 'url(' + callBack + ') no-repeat fixed',
                    backgroundSize: 'cover',
                    color: 'white',
                    position: 'relative',
                    flexDirection: 'column',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 4,
                    pt: 4,
                    pb: 4,
                }}
            >
        <Typography 
          variant="h3" 
          color="white" 
          textAlign="center" 
          gutterBottom 
          sx={{ textShadow: '3px 3px 4px rgba(1, 0, 0, 0.5)' }}
        >
          Kontakt mig
        </Typography>
                <Container
                    sx={{
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        borderRadius: 2,
                        p: 3,
                        textAlign: 'center',
                        width: '80%',
                        maxWidth: '400px',
                    }}
                >
                    <PhoneIcon sx={{ fontSize: '3rem', mb: 1 }} />
                    <Typography variant="h6">
                        Ring til os
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        Dt er altid gratis at ringe
                    </Typography>
                    <Button
                        variant="contained"
                        color="success"
                        startIcon={<PhoneIcon />}
                        href="tel:+4542264933"
                    >
                        +45 42 26 49 33
                    </Button>
                </Container>
                <Container
                    sx={{
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        borderRadius: 2,
                        p: 3,
                        textAlign: 'center',
                        width: '80%',
                        display: msgSent ? 'flex' : 'block',
                        maxWidth: '400px',
                        minHeight: '440px',
                        
                    }}>

            {msgSent ? 
                (
                  <Box
                        textAlign="center"
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          margin: '0 auto',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <SendIcon sx={{ fontSize: '3rem', mb: 1 }} />
                        <Typography variant="h6" gutterBottom>
                          Tak for din besked
                        </Typography>
                      </Box>
               
                ) : (
                        <form onSubmit={handleSubmit}>
                        <EmailIcon sx={{ fontSize: '3rem', mb: 1 }} />
                        <Typography variant="h6" gutterBottom>
                            Send os en email
                        </Typography>
                        <Box
                            component="div"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,

                            }}
                        >
                            <TextField
                                variant="outlined"
                                label="Et navn"
                                fullWidth
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                                InputProps={{
                                    sx: { backgroundColor: 'white' },
                                }}
                            />
                            <TextField
                                variant="outlined"
                                label="Din Email"
                                fullWidth
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                InputProps={{
                                    sx: { backgroundColor: 'white' },
                                }}
                            />
                            <TextField
                                variant="outlined"
                                label="Din besked: tilføj info om dit problem"
                                multiline
                                rows={4}
                                fullWidth
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                required
                                InputProps={{
                                    sx: { backgroundColor: 'white' },
                                }}
                            />
                            <Button
                                variant="contained"
                                color="success"
                                endIcon={<SendIcon />}
                                type="submit"
                            >
                                Send
                            </Button>
                        </Box>
                    </form>
            )}
                </Container>
            </Box>
        </Element>
    );
};

export default Contact;


